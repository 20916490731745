import { Injectable } from '@angular/core';
import { ToastComponent } from 'src/app/shared/toast/toast.component';

@Injectable()
export class ToastService {
  private toastComponent: ToastComponent | null = null;

  setToastComponent(component: ToastComponent): void {
    this.toastComponent = component;
  }

  showToast(message: string): void {
    if (this.toastComponent) {
      this.toastComponent.showToast(message);
    }
  }
}